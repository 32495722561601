import React from "react"
import { Helmet } from "react-helmet"

import SEO from "./seo"

import { Header, Footer } from "../slices"
import { Global, css } from "@emotion/core"
import { Box, Flex } from "@chakra-ui/core"

import Preloader from "../design-system/preloader"

const Layout = ({ children, nav, siteTitle, host, seoData, modal }) => {
  return (
    <>
      <Global
        styles={css`
          body {
            overscroll-behavior: none;
            background: transparent;
          }

          html.wf-active .wf-loading-overlay {
            display: none;
          }

          button:focus {
            outline: 0;
          }

          a p,
          button {
            font-weight: 500;
          }
        `}
      />
      <SEO siteTitle={siteTitle} host={host} {...seoData} />
      <Box className="wf-loading-overlay">
        <Preloader />
      </Box>
      <Helmet>
        <meta
          name="google-site-verification"
          content="28YZp4xBWssny0MP3bW4OgKKlbqbtHqgboUHfsX_GXA"
        />
      </Helmet>
      <Flex direction="column" minHeight="100vh">
        <Header nav={nav} />
        <Flex direction="column" as="main" style={{ flexGrow: 1 }}>
          {children}
        </Flex>
        <Footer />
      </Flex>
    </>
  )
}

export default Layout
