import React from "react"
import Button from "./button"

const DownloadButton = ({ children, ...props }) => (
  <Button as="a" target="_blank" rel="noopener noreferrer" download {...props}>
    {children}
  </Button>
)

export default DownloadButton
