import React from "react"
import { Box } from "@chakra-ui/core"
import { keyframes } from "@emotion/core"

const bounce = keyframes`
    0%,
    100% {
        transform: scale(0, 1);
    }
    50% {
        transform: scale(1, 1);
    }
`

const Preloader = ({}) => (
  <Box
    style={{
      background: "white",
      display: "block",
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      opacity: 1,
      zIndex: "99999",
    }}
  >
    <Box
      width="100%"
      height="5px"
      position="absolute"
      margin="0 auto"
      right="0"
      left="0"
      top="0"
    >
      <Box
        width="100%"
        height="5px"
        bg="green.300"
        position="absolute"
        top="0"
        left="0"
        animation={`${bounce} 4s infinite ease-in-out`}
      />
    </Box>
  </Box>
)

export default Preloader
