import React from "react"
import { Flex } from "@chakra-ui/core"

const SectionWrapper = ({ children, ...props }) => {
  return (
    <Flex
      direction="column"
      bg={"transparent"}
      alignItems="center"
      width="100vw"
      {...props}
    >
      {children}
    </Flex>
  )
}

const Section = ({ children, ...props }) => (
  <Flex
    as="section"
    direction="columns"
    px={{ base: 0, md: 5 }}
    {...props}
    maxWidth={{ base: "100vw", sm: "1182px" }}
    width="100%"
  >
    {children}
  </Flex>
)

export { SectionWrapper, Section }
