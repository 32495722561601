import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Logo from "../design-system/logo"
import { Box, Heading, Flex, Text } from "@chakra-ui/core"
import { SectionWrapper, Section } from "../design-system/layout"

const FooterBg = () => (
  <StaticQuery
    query={graphql`
      query FooterBG {
        bg: file(relativePath: { regex: "/footer-bg.png/" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return data.bg ? (
        <Img
          fluid={data.bg.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 100%"
          style={{ height: "100%", filter: "grayscale(100%)", opacity: 0.2 }}
        />
      ) : null
    }}
  />
)

const Footer = ({ withBG, bg }) => {
  return (
    <SectionWrapper as="footer" position="relative">
      <Box
        position="absolute"
        top="0"
        height="100%"
        width="100%"
        zIndex={-1}
        bg="#13253f"
      >
        <FooterBg />
      </Box>
      <Flex
        px={3}
        direction="column"
        mt={24}
        style={{ maxWidth: "1280px", width: "100%" }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          alignItems="flex-start"
          flexWrap="wrap"
        >
          <Box
            className="logo"
            px={{ base: 0, lg: 1, lg2: 3 }}
            mb={16}
            stretch={1}
            order={{ base: -2, lg: 0 }}
            alignSelf={{ base: "flex-start", md: "center" }}
            width={{ base: "100%", sm: "50%", lg2: "250px" }}
          >
            <Link to="/">
              <Logo size="lg" />
            </Link>
          </Box>

          <Box
            className="item"
            px={{ base: 0, lg: 1, lg2: 3 }}
            mb={16}
            width={{ base: "100%", sm: "50%", lg2: "250px" }}
          >
            <Heading as="h3" color="white" fontSize="xl" fontWeight="semibold">
              London
            </Heading>
            <Box
              width="50px"
              height="2px"
              bg="green.300"
              mt={1}
              zIndex="1"
              mb={2}
              position="relative"
            />
            <Box fontSize="md" color="gray.300" lineHeight={"200%"} py={2}>
              <Text>71-75 Shelton Street</Text>
              <Text>London</Text>
              <Text>WC2H 9JQ</Text>
              <Text>United Kingdom</Text>
            </Box>
          </Box>

          <Box
            className="item"
            px={{ base: 0, lg: 1, lg2: 3 }}
            mb={16}
            width={{ base: "100%", sm: "50%", lg2: "250px" }}
          >
            <Heading as="h3" color="white" fontSize="xl" fontWeight="semibold">
              Barcelona
            </Heading>
            <Box
              width="50px"
              height="2px"
              bg="green.300"
              mt={1}
              zIndex="1"
              mb={2}
              position="relative"
            />
            <Box fontSize="md" color="gray.300" lineHeight={"200%"} py={2}>
              <Text>Carrer de Floridablanca, 92</Text>
              <Text>Barcelona</Text>
              <Text>08015</Text>
              <Text>Spain</Text>
            </Box>
          </Box>
          <Box
            order={{ base: -1, lg: 0 }}
            className="item"
            px={{ base: 0, lg: 1, lg2: 3 }}
            mb={16}
            width={{ base: "100%", sm: "50%", lg2: "250px" }}
          >
            <Heading as="h3" color="white" fontSize="xl" fontWeight="semibold">
              Contact
            </Heading>
            <Box
              width="50px"
              height="2px"
              bg="green.300"
              mt={1}
              zIndex="1"
              mb={2}
              position="relative"
            />
            <Box fontSize="md" color="gray.300" lineHeight={"200%"} py={2}>
              <Text
                _hover={{
                  color: "green.300",
                }}
              >
                <a href="mailto:hello@cecubed.co.uk" target="_top">
                  hello@cecubed.co.uk
                </a>
              </Text>
              <Text
                _hover={{
                  color: "green.300",
                }}
              >
                <a href="tel:+44 781 450 2494">+44 781 450 2494</a>
              </Text>
              <Text
                _hover={{
                  color: "green.300",
                }}
              >
                <a href="tel:+34 671 615 086">+34 671 615 086</a>
              </Text>
              <Flex className="social" justifyContent="flex-start" mt={2}>
                <Flex
                  as="a"
                  height="35px"
                  width="35px"
                  justifyContent="center"
                  alignItems="center"
                  target="_blank"
                  mr={3}
                  borderRadius="50%"
                  _hover={{
                    bg: "green.500",
                  }}
                  border="1px solid"
                  borderColor="green.400"
                  transition="all 0.3s"
                  href="https://www.linkedin.com/company/ce-cubed/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    style={{ width: "15px", height: "auto" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#ffffff"
                      d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                    />
                  </svg>
                </Flex>
                <Flex
                  as="a"
                  height="35px"
                  width="35px"
                  justifyContent="center"
                  alignItems="center"
                  target="_blank"
                  borderRadius="50%"
                  _hover={{
                    bg: "green.500",
                  }}
                  border="1px solid"
                  borderColor="green.400"
                  transition="all 0.3s"
                  href="https://www.linkedin.com/company/ce-cubed/"
                  target="_blank"
                  href="https://www.instagram.com/cecubed/?hl=en"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    style={{ width: "15px", height: "auto" }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#ffffff"
                      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                    />
                  </svg>
                </Flex>
              </Flex>
            </Box>
          </Box>
        </Flex>
        <Flex
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems={{ base: "center", md: "center" }}
          color="gray.600"
          fontSize={{ base: "sm", md: "md" }}
          direction={{ base: "column", md: "row" }}
          my={8}
          mx={{ base: 0, lg: 1, lg2: 3 }}
        >
          <Flex
            order={{ base: 1, md: 0 }}
            mt={{ base: 2, md: 0 }}
            alignItems="center"
          >
            <Text mr={2} fontSize="md">
              &copy;
            </Text>
            <Text fontSize="md">ce cubed. {new Date().getFullYear()}</Text>
          </Flex>

          <Flex>
            <Box
              mx={{ base: 1, md: 3 }}
              _hover={{
                color: "green.300",
              }}
            >
              <a href="/terms-and-conditions" target="_blank">
                Terms & Conditions
              </a>
            </Box>
            <Text dangerouslySetInnerHTML={{ __html: "&ndash;" }} />
            <Box
              mx={{ base: 1, md: 3 }}
              _hover={{
                color: "green.300",
              }}
            >
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </Box>
            <Text dangerouslySetInnerHTML={{ __html: "&ndash;" }} />
            <Box
              mx={{ base: 1, md: 3 }}
              _hover={{
                color: "green.300",
              }}
            >
              <a href="/cookie-policy" target="_blank">
                Cookie Policy
              </a>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </SectionWrapper>
  )
}

export default Footer
