/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.AWS_CALC_REGION,
  aws_appsync_graphqlEndpoint: process.env.AWS_CALC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.AWS_CALC_REGION,
  aws_appsync_authenticationType: process.env.AWS_CALC_AUTH_TYPE,
  aws_cognito_identity_pool_id: process.env.AWS_CALC_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.AWS_CALC_REGION,
  aws_user_pools_id: process.env.AWS_CALC_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.AWS_CALC_USER_POOL_CLIENT_ID,
  oauth: {},
}

export default awsmobile
