import React from "react"
import { Link } from "gatsby"
import {
  Box,
  Heading,
  Flex,
  PseudoBox,
  Button,
  Text,
  SimpleGrid,
} from "@chakra-ui/core"
import SectionHeading from "../design-system/section-heading"
import { SectionWrapper, Section } from "../design-system/layout"

const Benefits = ({ title, benefits, id }) => {
  return (
    <SectionWrapper alignItems="center" justifyContent="center" id={id}>
      <Section my={8} flexDirection="column">
        <SectionHeading mx={3}>
          <Text dangerouslySetInnerHTML={{ __html: title }} />
        </SectionHeading>
        <SimpleGrid width="100%" my={10} columns={{ base: 2, md: 4 }}>
          {benefits.map(({ icon, heading, text }) => (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-top"
              m={5}
            >
              <Box width="60px" m={2}>
                <img src={icon.url} style={{ height: "60px", width: "60px" }} />
              </Box>
              <Flex
                alignItems="center"
                m={1}
                minHeight={{ base: "40px", xl: "unset" }}
              >
                <Heading
                  as="h3"
                  textAlign="center"
                  dangerouslySetInnerHTML={{ __html: heading }}
                  fontWeight="semibold"
                  fontSize={{ base: "md", lg: "lg" }}
                  color="gray.900"
                />
              </Flex>
              <Text
                dangerouslySetInnerHTML={{ __html: text }}
                fontSize={{ base: "sm", lg: "md" }}
                textAlign="center"
                color="gray.700"
                m={1}
              />
            </Flex>
          ))}
        </SimpleGrid>
      </Section>
    </SectionWrapper>
  )
}

export default Benefits
