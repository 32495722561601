import React from "react"
import { Link } from "gatsby"
import {
  Box,
  Heading,
  Flex,
  PseudoBox,
  Button,
  Text,
  SimpleGrid,
  useTheme,
} from "@chakra-ui/core"
import { SectionWrapper, Section } from "../design-system/layout"
import SectionHeading from "../design-system/section-heading"
import { css } from "@emotion/core"

const GetStarted = ({ title, boxes, id }) => {
  const { colors, shadows } = useTheme()
  return (
    <SectionWrapper alightItems="center" justifyContent="center" my={8} id={id}>
      <Section my={8} direction="column">
        <SectionHeading mx={3}>
          <Text dangerouslySetInnerHTML={{ __html: title }} />
        </SectionHeading>
        <Flex
          justifyContent="center"
          my={8}
          mx={3}
          direction={{ base: "column", lg: "row" }}
          alignItems={{ base: "center", lg: "stretch" }}
        >
          {boxes.map((box, index) => (
            <>
              {index == 3 && <Box bg="white" height="100%" width="16px"></Box>}
              <Flex
                boxShadow={{ base: "unset", lg: "md" }}
                border="1px solid"
                borderColor={index < 3 ? "blue.300" : "gray.300"}
                _hover={{
                  "& > div > div": {
                    borderTopColor: index < 3 ? "blue.400" : "gray.300",
                    borderRightColor: index < 3 ? "blue.400" : "gray.300",
                  },
                  "& .get-started_circle": {
                    bg: index < 3 ? "blue.400" : "gray.300",
                  },
                }}
                css={css`
                  @media screen and (min-width: 62em) {
                    &:hover {
                      border-color: ${index < 3
                        ? colors.blue["400"]
                        : colors.gray["300"]};
                      box-shadow: ${shadows.lg};
                      & > div > div {
                        border-top-color: ${index < 3
                          ? colors.blue["400"]
                          : colors.gray["300"]};
                        border-right-color: ${index < 3
                          ? colors.blue["400"]
                          : colors.gray["300"]};
                      }
                      & .get-started_circle {
                        background: ${index < 3
                          ? colors.blue["400"]
                          : colors.gray["300"]};
                      }
                    }
                  }
                `}
                borderRadius={"2px"}
                //bg={`gray.${index == 3 ? 200 : 200 + index * 100}`}
                width={{ base: "100%", lg: "250px" }}
                maxWidth={"600px"}
                direction={{ base: "row", lg: "column" }}
                mr={{ base: 0, lg: index == 3 ? 0 : 2 }}
                mb={{ base: index == 3 ? 0 : 2, lg: 0 }}
                position="relative"
              >
                <Flex
                  flexShrink={0}
                  className="get-started_circle"
                  alignSelf="center"
                  m={{ base: 5, lg: 0 }}
                  mt={{ base: 5, lg: 8 }}
                  height="80px"
                  width="80px"
                  bg={index < 3 ? "blue.300" : "gray.300"}
                  borderRadius="50%"
                  alignItems="center"
                  justifyContent="center"
                  boxShadow="md"
                >
                  <Text
                    ml={index === 0 ? -1 : 0}
                    fontFamily="Rubik"
                    lineHeight="60px"
                    fontWeight="bold"
                    fontSize="6xl"
                    color="white"
                  >
                    {index == 3 ? `+` : index + 1}
                  </Text>
                </Flex>

                <Flex direction="column">
                  <Heading
                    mx={5}
                    mt={5}
                    mb={4}
                    pb={1}
                    as="h3"
                    fontSize="xl"
                    //fontFamily="Muli"
                    width="auto"
                    textAlign="center"
                    fontWeight="semibold"
                    borderBottom="1px solid"
                    borderColor="gray.600"
                    color={index == 3 ? `gray.600` : "gray.700"}
                  >
                    {box.title}
                  </Heading>
                  <Text
                    dangerouslySetInnerHTML={{ __html: box.body }}
                    color={index == 3 ? `gray.600` : "gray.800"}
                    fontSize="sm"
                    mx={5}
                    mb={12}
                  />
                  {index < 2 && (
                    <Box
                      bg="white" //{`gray.${index == 3 ? 200 : 200 + index * 100}`}
                      width="35px"
                      height="35px"
                      //borderRadius={6}
                      position="absolute"
                      transform={{
                        base: "rotate(135deg)",
                        lg: "rotate(45deg)",
                      }}
                      right={{ base: "unset", lg: "-18px" }}
                      top={{ base: "unset", lg: "23px" }}
                      left={{ base: "40px", lg: "unset" }}
                      bottom={{ base: "-18px", lg: "unset" }}
                      zIndex={2}
                      borderTop="1px solid"
                      borderTopColor={"blue.300"}
                      borderRight="1px solid"
                      borderRightColor={"blue.300"}
                    />
                  )}
                  {index > 0 && index < 3 && (
                    <Box
                      bg="white"
                      width="42px"
                      height="42px"
                      //borderRadius={6}
                      position="absolute"
                      transform={{
                        base: "rotate(135deg)",
                        lg: "rotate(45deg)",
                      }}
                      top={{ base: "-21px", lg: "19px" }}
                      left={{ base: "38px", lg: "-21px" }}
                      zIndex={1}
                      borderTop="1px solid"
                      borderTopColor={"blue.300"}
                      borderRight="1px solid"
                      borderRightColor={"blue.300"}
                    />
                  )}
                  {box.label && (
                    <Text
                      width="100%"
                      textAlign="center"
                      position={{ base: "relative", lg: "absolute" }}
                      top="-30px"
                      color={index < 3 ? "blue.400" : "gray.500"}
                      fontWeight="semibold"
                      fontSize="xl"
                      zIndex={1}
                    >
                      {box.label}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </>
          ))}
        </Flex>
      </Section>
    </SectionWrapper>
  )
}

export default GetStarted
