import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Box, Heading, Flex, Text } from "@chakra-ui/core"
import { SectionWrapper, Section } from "../../design-system/layout"
import TextInput from "../../design-system/text-input"
import Button from "../../design-system/button"
import Bar from "../../design-system/bar"

const Share = ({ title, subtitle, link, button }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    let timeout = null
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false)
      }, 1000)
    }

    return () => timeout && clearTimeout(timeout)
  }, [copied])

  return (
    <SectionWrapper
      alignItems="center"
      justifyContent="center"
      position="relative"
      bg="gray.100"
      mt={12}
      mb={8}
    >
      <Section
        my={16}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Bar position="absolute" top="-108px" />
        <Heading
          as="h3"
          mx={3}
          mb={1}
          fontSize="2xl"
          fontWeight="normal"
          lineHeight="150%"
          color="gray.800"
          textAlign="center"
        >
          <Text dangerouslySetInnerHTML={{ __html: title }} />
        </Heading>
        <Heading
          as="h4"
          mx={5}
          mb={1}
          fontSize="xl"
          fontWeight="normal"
          lineHeight="150%"
          color="gray.800"
          textAlign="center"
        >
          <Text dangerouslySetInnerHTML={{ __html: subtitle }} />
        </Heading>
        <Flex mx={5} mt={5}>
          <TextInput id="shareUrl" value={link} mr={2} />
          <Button
            onClick={e => {
              const copyText = document.getElementById("shareUrl")

              /* Select the text field */
              copyText.select()
              copyText.setSelectionRange(0, 99999) /*For mobile devices*/

              /* Copy the text inside the text field */
              document.execCommand("copy")
              copyText.blur()
              setCopied(true)
            }}
          >
            {copied ? "Copied..." : button}
          </Button>
        </Flex>
        <Bar position="absolute" bottom="-108px" />
      </Section>
    </SectionWrapper>
  )
}

export default Share
