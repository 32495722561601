import React from "react"
import { Link } from "gatsby"
import { Box, Heading, Flex, Text } from "@chakra-ui/core"
import { SectionWrapper, Section } from "../design-system/layout"
import EmailInput from "../design-system/email-input"
import Button from "../design-system/button"
import Bar from "../design-system/bar"
import NetlifyForm from "react-netlify-form"

const Cfa = ({
  signupSuccess,
  slunt,
  signupPlaceholder,
  signupButton,
  signupTitle,
  signupSubtitle,
  id,
  cfaType,
  campaign,
}) => {
  const [email, setEmail] = React.useState("")
  const [showError, toggleError] = React.useState(false)
  const [showSuccess, toggleSuccess] = React.useState(false)

  React.useEffect(() => {
    let timeout = null
    if (showError) {
      timeout = setTimeout(() => {
        toggleError(false)
      }, 10000)
    }

    return () => timeout && clearTimeout(timeout)
  }, [showError])

  React.useEffect(() => {
    let timeout = null
    if (showSuccess) {
      timeout = setTimeout(() => {
        toggleSuccess(false)
      }, 10000)
    }

    return () => timeout && clearTimeout(timeout)
  }, [showSuccess])

  return (
    <SectionWrapper
      alignItems="center"
      justifyContent="center"
      position="relative"
      mt={slunt ? "-12px" : 0}
      id={id}
    >
      <Section
        my={slunt ? { base: 24, lg: "6.5rem" } : { base: 16, lg: 20 }}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Bar
          position="absolute"
          top={
            slunt
              ? { base: "-108px", lg: "-116px" }
              : { base: "-108px", lg: "-124px" }
          }
        />

        <Heading
          as="h3"
          mx={3}
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight="normal"
          lineHeight="150%"
          color="gray.800"
          textAlign="center"
        >
          <Text dangerouslySetInnerHTML={{ __html: signupTitle }} />
        </Heading>
        {signupSubtitle && (
          <Heading
            as="h4"
            mx={3}
            my={1}
            maxWidth="28em"
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="normal"
            lineHeight="150%"
            color="gray.800"
            textAlign="center"
          >
            <Text dangerouslySetInnerHTML={{ __html: signupSubtitle }} />
          </Heading>
        )}
        <NetlifyForm
          name="Newsletter"
          onSuccess={() => {
            toggleSuccess(true)
            setEmail("")
          }}
          onError={() => toggleError(true)}
        >
          {({ loading, error, success }) => (
            <>
              <Flex mt={5}>
                <EmailInput
                  placeholder={signupPlaceholder}
                  mr={2}
                  required
                  name="email"
                  value={email}
                  onChange={v => setEmail(v.target.value)}
                />
                <input type="hidden" name="campaign" value={campaign} />
                <input type="hidden" name="cfa" value={`CFA-${cfaType}`} />
                <Button
                  isLoading={loading}
                  type="submit"
                  id={`cfa-${campaign}-${cfaType}-submit`}
                >
                  {signupButton}
                </Button>
              </Flex>
            </>
          )}
        </NetlifyForm>
        <Flex
          width="100%"
          justifyContent="center"
          position="relative"
          height="12px"
          height="fit-content"
        >
          <Text
            mx={3}
            mt={2}
            opacity={showError ? 1 : 0}
            transition="opacity 1s"
            fontSize="md"
            color="red.400"
            position="absolute"
            textAlign="center"
            zIndex={1}
          >
            <i>Something went wrong, please try again later!</i>
          </Text>

          <Text
            position={{ base: "relative", lg: "absolute" }}
            height={showSuccess ? { base: "50px", lg: "0" } : 0}
            mx={3}
            mt={2}
            opacity={showSuccess ? 1 : 0}
            transition="all 1s"
            fontSize="md"
            color="gray.700"
            fontWeight="medium"
            textDecoration="underline"
            textDecorationColor="inherit"
            textAlign="center"
            zIndex={1}
          >
            <i>{signupSuccess}</i>
          </Text>
        </Flex>
        <Bar
          position="absolute"
          bottom={
            slunt
              ? { base: "-108px", lg: "-116px" }
              : { base: "-108px", lg: "-124px" }
          }
        />
      </Section>
      <Box
        height="100%"
        width="100%"
        top={0}
        position="absolute"
        zIndex={-1}
        bg={"gray.50"}
        style={{
          clipPath: slunt
            ? "polygon(100% 20%, 0% 0%, 0% 100%, 100% 80%)"
            : "unset",
          "-webkit-clip-path": slunt
            ? "polygon(100% 20%, 0% 0%, 0% 100%, 100% 80%)"
            : "unset",
        }}
      />
    </SectionWrapper>
  )
}

export default Cfa
