import React from "react"
import { Link } from "gatsby"
import { Box, Heading, Flex, Text, useTheme } from "@chakra-ui/core"
import Logo from "../design-system/logo"
import Button from "../design-system/button"
import DownloadButton from "../design-system/download-button"
import { SectionWrapper } from "../design-system/layout"
import AnchorLink from "react-anchor-link-smooth-scroll"
import styled from "@emotion/styled"
import { Location } from "@reach/router"
import { renderText } from "../utils"

const LinkItem = ({
  children,
  section,
  link,
  isButton,
  location,
  currentSectionAnchor,
  onClick,
  ...props
}) => {
  let Type = null
  let Component = Text
  let linkPathname = null
  let active = null
  if (!link) return null
  if (link._linkType == "Link.file") {
    Component = isButton ? DownloadButton : Text
    Type = Link
    linkPathname = link.url
  } else {
    // assume that it is _linkType == "Link.document"
    linkPathname = link._meta.uid == null ? "/" : "/" + link._meta.uid

    Type = linkPathname == location.pathname && section ? AnchorLink : Link
    Component = isButton ? Button : Text
    active = section
      ? linkPathname == location.pathname &&
      currentSectionAnchor == "#" + section
      : linkPathname == location.pathname
  }

  return (
    <Component
      as={Type || Link}
      href={section && "#" + section}
      to={`${linkPathname == "/" ? "" : linkPathname}${
        section ? "/#" + section : ""
        }`}
      offset="70"
      onClick={onClick}
      color={active ? "gray.800" : "gray.600"}
      fontWeight="medium"
      fontSize="md"
      position={"relative"}
      fontFamily="body"
      display="block"
      transition="width 0.5s"
      _hover={{
        textDecoration: "none",
        _before: {
          width: "100%",
        },
      }}
      _before={{
        content: "''",
        position: "absolute",
        left: "0",
        bottom: "-2px",
        transition: "all 0.5s",
        right: "0",
        margin: "0 auto",
        background: active ? "gray.800" : "gray.400",
        height: "1px",
        width: active ? "100%" : 0,
      }}
      {...props}
    >
      {children}
    </Component>
  )
}

const Header = ({ nav, ...props }) => {
  const [scrolling, setScrolling] = React.useState(false)
  const [currentSectionAnchor, setCurrentSectionAnchor] = React.useState(null)
  const ref = React.useRef(null)
  const [show, setShow] = React.useState(false)
  const handleToggle = () => setShow(!show)
  const { colors } = useTheme()

  React.useEffect(() => {
    function resizeHeaderOnScrollAddFindActiveElement() {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop
      const shrinkOn = 50

      if (distanceY > shrinkOn) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }

      const allAnchorNavs = document.querySelectorAll(
        `#why-us, #benefits, #calculator, #contact-us, #get-started`
      )

      let newActiveStep = null
      const headerRect = ref.current.getBoundingClientRect()

      let newActiveDistanceToStepNav = null
      allAnchorNavs.forEach(step => {
        const stepRect = step.getBoundingClientRect()

        if (!newActiveStep) {
          newActiveDistanceToStepNav = Math.abs(headerRect.top - stepRect.top)
          newActiveStep = step
          return
        }

        const stepDistanceToStepNav = Math.abs(headerRect.top - stepRect.top)
        if (stepDistanceToStepNav < newActiveDistanceToStepNav) {
          newActiveDistanceToStepNav = stepDistanceToStepNav
          newActiveStep = step
        }
      })

      setCurrentSectionAnchor(newActiveStep && `#${newActiveStep.id}`)
    }

    window.addEventListener("scroll", resizeHeaderOnScrollAddFindActiveElement)

    return () => {
      window.removeEventListener(
        "scroll",
        resizeHeaderOnScrollAddFindActiveElement
      )
    }
  }, [])
  return (
    <SectionWrapper
      as="header"
      bg="white"
      position="fixed"
      zIndex={"sticky"}
      boxShadow={scrolling && "sm"}
    >
      <Flex
        {...props}
        as="nav"
        align="center"
        justify="space-between"
        wrap="no-wrap"
        color="gray.600"
        px={3}
        style={{ maxWidth: "1280px", width: "100%" }}
        ref={ref}
      >
        <Flex
          transition={"all 0.3s"}
          my={scrolling ? 2 : 4}
          mx={{ base: 0, lg: 1, lg2: 3 }}
        >
          <Link href="/" style={{ display: "flex", alignItems: "center" }}>
            <Logo size={scrolling ? "sm" : "md"} />
          </Link>
        </Flex>

        {nav && (
          <Location>
            {({ location }) => (
              <Flex
                display="flex"
                position={{ base: "fixed", lg: "relative" }}
                alignItems="center"
                direction={{ base: "column", lg: "row" }}
                justifyContent={{
                  base: "center",
                  lg: nav.length > 1 ? "space-between" : "flex-end",
                }}
                justifySelf={{ base: "center", lg: "flex-end" }}
                mx={{ base: 0, lg: 1, lg2: 3 }}
                mb={1}
                zIndex={2}
                bg="white"
                maxWidth={{ base: "unset", lg: "716px" }}
                width={{ base: "100vw", lg: "100%" }}
                height={{ base: "100vh", lg: "auto" }}
                transform={{
                  base: show ? "translateY(0%)" : "translateY(-100%)",
                  lg: "unset",
                }}
                transition="transform 0.5s"
                left={{ base: 0, lg: "unset" }}
                top={{ base: 0, lg: "unset" }}
              >
                {nav.map(({ label, section, link, isButton }) => {
                  if (label.includes("ce3")) {
                    label = label.replace(
                      "ce3",
                      `(ce)<sup style={{ position: "absolute", top: "6px" }}>3</sup>`
                    )
                  }
                  return isButton && isButton == true ? (
                    <Box my={scrolling ? 2 : 0}>
                      {link && link._linkType == "Link.file" ? (
                        <DownloadButton
                          id="cfa-header-download-report"
                          onClick={handleToggle}
                          size="sm"
                          href={link.url}
                        >
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: renderText(label),
                            }}
                          />
                        </DownloadButton>
                      ) : (
                          <Button
                            onClick={handleToggle}
                            size="sm"
                            href={`#${section}`}
                            as={AnchorLink}
                            offset="70"
                          >
                            <Text
                              dangerouslySetInnerHTML={{
                                __html: renderText(label),
                              }}
                            />
                          </Button>
                        )}
                    </Box>
                  ) : (
                      <Box my={3} cursor="pointer">
                        <LinkItem
                          section={section}
                          link={link}
                          isButton={isButton}
                          location={location}
                          currentSectionAnchor={currentSectionAnchor}
                          onClick={handleToggle}
                        >
                          <Text
                            style={{ position: "relative" }}
                            dangerouslySetInnerHTML={{
                              __html: renderText(label),
                            }}
                          />
                        </LinkItem>
                      </Box>
                    )
                })}
              </Flex>
            )}
          </Location>
        )}

        <Box
          zIndex={3}
          display={{ base: "block", lg: "none" }}
          onClick={handleToggle}
        >
          <BurgerLine
            transform={show ? "translate(4px, -1px) rotate(45deg)" : null}
            bg={show ? "gray.800" : "gray.700"}
            transformOrigin="0% 0%"
          />
          <BurgerLine
            bg={show ? "gray.800" : "gray.700"}
            opacity={show ? 0 : 1}
          />
          <BurgerLine
            bg={show ? "gray.800" : "gray.700"}
            transform={show ? "translate(3px, 3px) rotate(-45deg) " : null}
            transformOrigin="0% 0%"
          />
        </Box>
      </Flex>
    </SectionWrapper>
  )
}

const BurgerLine = styled(Box)`
  display: block;
  width: 33px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;

  border-radius: 1px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
`

export default Header
