export const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

const subCO2 = text => {
  if (!text) return ""
  text = text.replace("CO2", "CO<sub>2</sub>")
  text = text.replace("co2", "co<sub>2</sub>")
  text = text.replace("Co2", "Co<sub>2</sub>")
  return text
}

const replaceNewLinesWithBRs = text => {
  if (!text) return ""
  text = text.replace(/(?:\r\n|\r|\n)/g, "<br />")
  return text
}

export const renderText = text => {
  text = subCO2(text)
  text = replaceNewLinesWithBRs(text)
  return text
}
