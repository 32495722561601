import React, { useState, useEffect, useRef } from "react"
import Select, { createFilter } from "react-select"
import { FixedSizeList as List } from "react-window"
import styled from "@emotion/styled"

const height = 60

const Element = styled.div`
  &.ListItemEven,
  &.ListItemOdd {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const formatOptionLabel = (
  { value, label, city, country, name, iata },
  { context, inputValue, selectValue }
) => {
  const airportName = name.replace(/airport/i, "").trim()
  return context === "value" ? (
    <div>
      {iata}
      {" - "}
      {airportName}
    </div>
  ) : (
    <div
      style={{
        height: "44px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      <div>
        <div style={{ fontSize: "16px", lineHeight: "20px" }}>
          {iata}
          {" - "}
          {airportName}
        </div>
        <div style={{ fontSize: "14px", opacity: "0.6" }}>
          {city}
          {", "}
          {country}
        </div>
      </div>
    </div>
  )
}

const StyledList = styled.div`
  width: 400px;
  @media (max-width: 987px) {
    width: 100%;
  }
`

const MenuList = props => {
  const { options, children, maxHeight, getValue, selectProps } = props
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height
  const outerRef = useRef(null)

  const { inputValue } = selectProps

  useEffect(() => {
    if (inputValue) {
      outerRef.current.scrollTop = 0
    }
  }, [inputValue])

  return (
    <StyledList
      as={List}
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      style={{
        position: "relative",
      }}
      outerRef={outerRef}
    >
      {({ index, style }) => <Element style={style}>{children[index]}</Element>}
    </StyledList>
  )
}

const selectStyles = ({ required, inputColors }) => ({
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    paddingTop: 0,
    paddingBottom: 0,
    width: "100%",
  }),
  menu: (base, state) => ({
    ...base,
    width: "400px",
    "@media (max-width: 987px)": {
      width: "100%",
    },
    zIndex: 3,
  }),
  control: (base, state) => ({
    ...base,
    // none of react-select's styles are passed to <Control />
    background: inputColors.bg,
    "&:hover": {
      borderColor: required
        ? inputColors.borderColorRequired
        : inputColors.borderColorIsFocused,
    },
    border: required
      ? `1px solid ${inputColors.borderColorRequired}`
      : !state.isFocused
      ? `1px solid ${inputColors.borderColor}`
      : `1px solid ${inputColors.borderColorIsFocused}`,
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white !important" : "#000",
    cursor: "pointer",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0",
    paddingBottom: "0",
    paddingTop: "0",
  }),
})

const customFilterOption = ({ data }, rawInput) => {
  const iata = data.iata
  //const cityWords = data.city.split(" ")
  //const nameWords = data.name.split(" ")

  return (
    iata.toLowerCase().startsWith(rawInput.toLowerCase()) ||
    data.city.toLowerCase().startsWith(rawInput.toLowerCase()) ||
    data.name.toLowerCase().startsWith(rawInput.toLowerCase()) ||
    data.city.toLowerCase().includes(` ${rawInput.toLowerCase()}`) ||
    data.name.toLowerCase().includes(` ${rawInput.toLowerCase()}`)
    /*cityWords.filter(word =>
      word.toLowerCase().startsWith(rawInput.toLowerCase())
    ).length ||
    nameWords.filter(word =>
      word.toLowerCase().startsWith(rawInput.toLowerCase())
    ).length*/
  )
}

const AirportSelector = ({
  options,
  onChange,
  value,
  required,
  inputColors,
}) => {
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => setSelectedValue(value), [value])

  return (
    <Select
      components={{ MenuList }}
      filterOption={createFilter({ ignoreAccents: false })}
      className="class-selector"
      styles={selectStyles({
        required: required && !selectedValue,
        inputColors,
      })}
      classNamePrefix="class-selector"
      formatOptionLabel={formatOptionLabel}
      name="color"
      options={options}
      onChange={onChange}
      value={
        selectedValue ? options.find(obj => obj.value === selectedValue) : null
      } // set selected value
      placeholder="City or Airport..."
      //menuIsOpen={true}
      filterOption={customFilterOption}
    />
  )
}

export default AirportSelector
