import React, { useState, useEffect, useLayoutEffect } from "react"
import { Box, Flex, Text } from "@chakra-ui/core"
import { SectionWrapper, Section } from "../../design-system/layout"

import AnnualCO2Budget from "../../design-system/infographics/annual-co2-budget"
import AnnualTreesBudget from "../../design-system/infographics/annual-trees-budget"
import AnnualCommuteBudget from "../../design-system/infographics/annual-commute-budget"
import { round } from "../../utils"

require("es6-promise").polyfill()
require("isomorphic-fetch")

const chartComponents = {
  AnnualCO2Budget,
  AnnualTreesBudget,
  AnnualCommuteBudget,
}

const Results = ({
  totalEmissionsKG,
  totalDistanceKM,
  totalNights,
  totalTrips,
  numberOfEmployees,
  calculationType,
  inputValues,
  heading,
  charts,
}) => {
  return (
    <>
      <SectionWrapper alignItems="center" justifyContent="center" my={10}>
        <Section flexDirection="column">
          {heading}
          {inputValues}
        </Section>
      </SectionWrapper>
      <SectionWrapper alignItems="center" justifyContent="center" bg="gray.50">
        <Section flexDirection="column">
          <Flex alignItems="center" justifyContent="center" my={3}>
            <Text
              fontSize="6xl"
              lineHeight="60px"
              fontWeight="bold"
              textAlign="center"
              color="red.400"
              mr={3}
            >
              {round(totalEmissionsKG / 1000, 1).toLocaleString("en")}
            </Text>
            <Flex
              direction="column"
              justifyContent="space-between"
              alignSelf="stretch"
            >
              <Box
                fontSize="lg"
                fontWeight="normal"
                color="gray.700"
                fontFamily="Muli"
                lineHeight="33px"
              >
                tonnes
              </Box>
              <Box
                fontFamily="Muli"
                fontWeight="bold"
                lineHeight="38px"
                fontSize="3xl"
                color="gray.700"
                mt="-10px"
              >
                CO<sub style={{ fontSize: "18px" }}>2</sub>
              </Box>
            </Flex>
          </Flex>
        </Section>
      </SectionWrapper>
      <SectionWrapper alignItems="center" justifyContent="center">
        <Section flexDirection="column" my={5}>
          <SubResult
            number={Math.round(totalTrips).toLocaleString("en")}
            label="trips"
          />
          <SubResult
            number={Math.round(totalDistanceKM).toLocaleString("en")}
            label="kms"
          />
          {totalNights > 0 && (
            <SubResult
              number={Math.round(totalNights).toLocaleString("en")}
              label="hotel nights"
              colour="#24c750"
            />
          )}
        </Section>
        <Section flexDirection="column" my={{ base: 5, md: 0, lg: 5 }}>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent={{
              base: "unset",
              md: "space-around",
              xl: "space-between",
            }}
            alignItems={{ base: "center", md: "unset" }}
            flexWrap="wrap"
          >
            {charts.map((props, index) => {
              const Component = chartComponents[props.id]
              return (
                <Box
                  my={{ base: index == 1 ? 16 : 0, md: 5 }}
                  mx={3}
                  width={{ base: "300px", md: "300px", lg: "300px" }}
                >
                  <Component
                    {...props}
                    employees={
                      calculationType == "company" ? numberOfEmployees : 1
                    }
                    kg={totalEmissionsKG}
                  />
                </Box>
              )
            })}
          </Flex>
        </Section>
      </SectionWrapper>
    </>
  )
}

const SubResult = ({ number, label, colour = "#0966bf" }) => (
  <Flex justifyContent="center" alignItems="flex-end">
    <Box
      fontSize="xl"
      fontWeight="semibold"
      mr={3}
      color="gray.900"
      width="50%"
      textAlign="right"
      lineHeight="30px"
    >
      {number}
    </Box>
    <Box
      fontSize="lg"
      fontWeight="normal"
      color="gray.600"
      width="50%"
      lineHeight="30px"
    >
      {label}
    </Box>
  </Flex>
)

export default Results
