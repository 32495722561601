import styled from "@emotion/styled"

const ChartWrapper = styled("div")`
  width: 300px;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  @media (max-width: 987px) {
    height: auto;
  }
`

export default ChartWrapper
