import React, { useState, useLayoutEffect } from "react"
import AnimatedNumber from "animated-number-react"
import moment from "moment"
import ChartWrapper from "./chart-wrapper"
import { Box, Flex, Text } from "@chakra-ui/core"

import InfographicContainer from "./infographic-container"

const NumberWrapperStyles = {
  alignItems: "flex-end",
  justifyContent: "center",
  width: "100%",
  my: 1,
}

const NumberStyles = {
  color: "gray.800",
  fontSize: "3xl",
  lineHeight: "30px",
  mr: 3,
  fontWeight: "bold",
  width: "50%",
  textAlign: "right",
}

const Chart = ({ kg }) => {
  const [days, setDays] = useState(0)
  const [months, setMonths] = useState(0)
  const [years, setYears] = useState(0)

  useLayoutEffect(() => {
    const interval = setTimeout(() => {
      //Round up always, because if you started traveling in a day it means u using that day
      const totalDays = Math.ceil((kg / 0.055 / 74) * 0.0569444444444444)

      const startDate = moment("2020-01-01T12")
      const endDate = moment("2020-01-01T12")
      endDate.add(totalDays, "days")

      var years = endDate.diff(startDate, "year")
      startDate.add(years, "years")

      var months = endDate.diff(startDate, "months")
      startDate.add(months, "months")

      var days = endDate.diff(startDate, "days")
      setYears(years)
      setMonths(months)
      setDays(days)
    }, 1000)

    return () => clearTimeout(interval)
  }, [kg])

  const formatValue = value => {
    return `${value.toFixed(0)}`.padStart(2, "0")
  }
  return (
    <ChartWrapper style={{ flexDirection: "column" }}>
      <Flex {...NumberWrapperStyles}>
        <Text {...NumberStyles}>
          <AnimatedNumber value={years} formatValue={formatValue} />
        </Text>
        <Text color="gray.600" fontSize="lg" width="50%" lineHeight="20px">
          years
        </Text>
      </Flex>
      <Flex {...NumberWrapperStyles}>
        <Text {...NumberStyles}>
          <AnimatedNumber value={months} formatValue={formatValue} />
        </Text>
        <Text color="gray.600" fontSize="lg" width="50%" lineHeight="20px">
          months
        </Text>
      </Flex>
      <Flex {...NumberWrapperStyles}>
        <Text {...NumberStyles}>
          <AnimatedNumber value={days} formatValue={formatValue} />
        </Text>
        <Text color="gray.600" fontSize="lg" width="50%" lineHeight="20px">
          days
        </Text>
      </Flex>
    </ChartWrapper>
  )
}

const AnnualCommuteBudget = ({ kg, title, summary, icon }) => (
  <InfographicContainer
    icon={icon}
    chart={<Chart kg={kg} />}
    title={title}
    text={summary}
  />
)

export default AnnualCommuteBudget
