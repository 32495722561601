import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import {
  Box,
  Flex,
  PseudoBox,
  Text,
  SimpleGrid,
  CloseButton,
  useTheme,
  Button as ChakraButton,
} from "@chakra-ui/core"

import Button from "../../design-system/button"
import Bar from "../../design-system/bar"

import SectionHeading from "../../design-system/section-heading"
import Toggle from "../../design-system/toggle"
import { calculateEmissions } from "../../graphql/queries"

import { SectionWrapper, Section } from "../../design-system/layout"
import Amplify, { API, graphqlOperation } from "aws-amplify"
import awsConfig from "../../aws-exports"

import AirportSelector from "../../design-system/airport-selector"
import ClassSelector from "../../design-system/class-selector"
import Heading from "../../design-system/heading"

import FrequencySelector from "../../design-system/frequency-selector"
import TextField from "../../design-system/text-field"
import Results from "./results"
import Share from "./share"
import styled from "@emotion/styled"

require("es6-promise").polyfill()
require("isomorphic-fetch")

Amplify.configure(awsConfig)

const SwapButton = styled(Box)`
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.blue["500"]};
      }
    }
  }
  &:active {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.blue["300"]};
      }
    }
  }
`

const MAX_ROWS = 5
const BUTTON_TYPE_NONE = 0
const BUTTON_TYPE_CALC = 1
const BUTTON_TYPE_RECALC = 2

const travelClassOptions = [
  { value: "economy", label: "Economy" },
  { value: "premium", label: "Premium" },
  { value: "business", label: "Business" },
]

const classCanBePremium = (origin, destination) => {
  if (!origin || !destination) return true
  if (
    origin.country === destination.country ||
    origin.continent === destination.continent
  ) {
    return false
  }
  return true
}

const getTrevelClassOptions = (airportOptions, origin, destination) => {
  if (!origin || !destination) return travelClassOptions

  if (
    !classCanBePremium(
      airportOptions.find(item => item.iata === origin),
      airportOptions.find(item => item.iata === destination)
    )
  ) {
    let options = [...travelClassOptions]
    return options.filter(item => item.value !== "premium")
  }

  return travelClassOptions
}

const requredFields = ["origin", "destination", "trips"]
const validateFields = [
  "origin",
  "destination",
  "trips",
  "travelClass",
  "nights",
]

const defaultEmptyState = {
  origin: null,
  destination: null,
  trips: null,
  frequency: "month",
  travelClass: null,
  nights: null,
  validate: false,
}

const getCalcs = async (values, employees, calcTypeCompany) => {
  try {
    const response = await API.graphql(
      graphqlOperation(calculateEmissions, {
        object: JSON.stringify(values),
        numberOfEmployees: calcTypeCompany ? employees : 1,
        calculationType: calcTypeCompany ? "company" : "individual",
      })
    )
    return response.data.calculateEmissions
  } catch (e) {
    console.log(e)
  }
}

const scrollToElement = el => () => {
  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
    return true
  }
  return false
}

const scrollToRef = ref => window.setTimeout(scrollToElement(ref.current), 10)

const Calculator = ({
  id,
  title,
  subtitle,
  calcTitleIcon,
  calcTitle,
  calcIndividualOptionLabel,
  limitReachedLabel,
  recalculateButtonLabel,
  calculateButtonLabel,
  resultsHeading,
  resultsHeadingCompany,
  charts,
  shareSection,
}) => {
  const [airportOptions, setAirportOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [calculatedResults, setCalculatedResults] = useState(null)
  const [calcButtonType, setCalcButtonType] = useState(BUTTON_TYPE_CALC)
  const [values, setValues] = useState([{ ...defaultEmptyState }])
  const [calcTypeCompany, toggleCalcTypeCompany] = useState(true)
  const [employees, setEmployees] = useState(100)
  const [resultEmployees, setResultEmployees] = useState(0)
  const [showValidationMessage, setShowValidationMessage] = useState(false)
  const [resultTypeCompany, setResultTypeCompany] = useState(false)
  const [shareableUrl, setShareableUrl] = useState(null)

  const { colors } = useTheme()

  const resultsBreakLineRef = useRef(null)

  const addOneMoreRoute = e => {
    e.preventDefault()
    if (values.length < MAX_ROWS) {
      setValues([...values, { ...defaultEmptyState }])
    }
  }

  useEffect(() => {
    const fetchData = () => {
      fetch(`/airportsV2.json`)
        .then(response => response.json())
        .then(data => setAirportOptions(data))
        .catch(error => console.log(error))
    }

    fetchData()
  }, [])

  const updateCalcButton = () => {
    if (calculatedResults && calcButtonType !== BUTTON_TYPE_RECALC) {
      setCalcButtonType(BUTTON_TYPE_RECALC)
    }
  }

  const changeValue = (index, name, value) => {
    let newValues = [...values]
    newValues[index] = { ...values[index], [name]: value }

    if (
      (name === "origin" || name === "destination") &&
      newValues[index].origin &&
      newValues[index].destination &&
      newValues[index].travelClass
    ) {
      if (
        !classCanBePremium(
          airportOptions.find(item => item.iata === newValues[index].origin),
          airportOptions.find(
            item => item.iata === newValues[index].destination
          )
        ) &&
        newValues[index].travelClass === "premium"
      ) {
        newValues[index].travelClass = null
      }
    }

    setValues([...newValues])
    updateCalcButton()
  }

  const validRow = (row, index) => {
    const filledInFields = validateFields.filter(key => row[key])
    return (
      (filledInFields.length == 0 && index) ||
      filledInFields.filter(key => requredFields.includes(key)).length ==
        requredFields.length
    )
  }

  const validForm = () => {
    return (
      values.filter((row, index) => validRow(row, index)).length ==
      values.length
    )
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (validForm()) {
      setLoading(true)
      const response = JSON.parse(
        await getCalcs(values, employees, calcTypeCompany)
      )

      setCalculatedResults(response)
      window &&
        setShareableUrl(
          `${window.location.protocol}//${window.location.host}/calculator?${response.id}`
        )
      setResultEmployees(employees)
      setResultTypeCompany(calcTypeCompany)
      setLoading(false)
      setCalcButtonType(BUTTON_TYPE_NONE)
      scrollToRef(resultsBreakLineRef)
    } else {
      setShowValidationMessage(true)
      setValues(values.map(item => ({ ...item, validate: true })))
    }
  }

  const inputColors = {
    borderColorIsFocused: colors.blue["300"],
    borderColorRequired: colors.red["300"],
    borderColor: colors.gray["400"],
    bg: colors.gray["50"],
  }

  return (
    <>
      <SectionWrapper
        alignItems="center"
        justifyContent="center"
        mt={10}
        id={id}
      >
        <Section flexDirection="column">
          <SectionHeading mx={3}>
            <Text dangerouslySetInnerHTML={{ __html: title }} />
          </SectionHeading>
          <Heading
            as="h3"
            mx={3}
            my={6}
            width={{ base: "auto", md: "20em" }}
            color="gray.700"
            fontSize={{ base: "xl", md: "2xl" }}
            fontWeight="normal"
            lineHeight="150%"
          >
            <Text dangerouslySetInnerHTML={{ __html: subtitle }} />
          </Heading>
        </Section>
        <Section
          mt={8}
          mb={3}
          alignItems="center"
          justifyContent="center"
          direction={{ base: "column", md: "row" }}
        >
          <Box ml={{ base: 0, md: "-66px" }}>
            <img
              src={calcTitleIcon.url}
              style={{ height: "100%", width: "66px" }}
            />
          </Box>
          <Heading
            as="h4"
            my={3}
            width={{ base: "auto", md: "25em" }}
            color="gray.700"
            mx={3}
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="medium"
            lineHeight="150%"
            textAlign="center"
          >
            <Text dangerouslySetInnerHTML={{ __html: calcTitle }} />
          </Heading>
        </Section>
        <Section
          my={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Box width="auto" px={8} bg="white">
            <Toggle
              on="Company"
              off="Individual"
              value={calcTypeCompany}
              onChange={() => {
                toggleCalcTypeCompany(!calcTypeCompany)
                updateCalcButton()
              }}
            />
          </Box>
          <Box
            width="100%"
            height="1px"
            bg="gray.300"
            zIndex={-1}
            position="absolute"
            top="20px"
          />
          {calcTypeCompany ? (
            <Flex my={5} height="32px">
              <Text mr={5} ontWeight="semibold" color="gray.600" fontSize="lg">
                <i>Company size:</i>
              </Text>
              <Box
                as="input"
                type="text"
                border="1px solid"
                borderRadius="4px"
                borderColor="gray.400"
                color="gray.700"
                width="80px"
                fontWeight="bold"
                lineHeight="30px"
                textAlign="center"
                style={{ "-webkit-appearance": "none" }}
                c
                px={3}
                _focus={{
                  outline: "none",
                  boxShadow: "none",
                  borderColor: "gray.500",
                }}
                value={employees}
                onChange={inputValue => {
                  let value = inputValue.target.value
                  value = value.replace(/\D/g, "")
                  value = value.replace(/^[0]/, "")
                  setEmployees(value)
                  updateCalcButton()
                }}
                onBlur={() => {
                  if (employees < 1) {
                    setEmployees(1)
                  }
                }}
              />
            </Flex>
          ) : (
            <Text
              height="32px"
              my={5}
              maxWidth="20em"
              textAlign="center"
              lineHeight="120%"
              fontWeight="normal"
              color="gray.700"
              fontSize="md"
            >
              <i>{calcIndividualOptionLabel}</i>
            </Text>
          )}
          <Flex direction="column" color="gray.700" width="auto" my={5} mx={3}>
            <Flex mb={3} mx={3} display={{ base: "none", calc: "flex" }}>
              <Column minWidth="250px" flexGrow={1}>
                <ColumnHeading>From*</ColumnHeading>
              </Column>
              <Column width="40px"></Column>
              <Column minWidth="250px" flexGrow={1}>
                <ColumnHeading>To*</ColumnHeading>
              </Column>
              <Column width="220px" mx={5}>
                <ColumnHeading>Round Trips*</ColumnHeading>
              </Column>
              <Column width="150px">
                <ColumnHeading>Class of Travel</ColumnHeading>
              </Column>
              <Column width="120px" justifyContent="center" pl={5}>
                <ColumnHeading textAlign="center" color="green.500">
                  Hotel nights
                  <br />
                  per Trip
                </ColumnHeading>
              </Column>
            </Flex>
            <Flex
              direction={{ base: "row", calc: "column" }}
              flexWrap="wrap"
              justifyContent="center"
            >
              {values.map((object, index) => {
                const {
                  origin,
                  destination,
                  trips,
                  frequency,
                  travelClass,
                  nights,
                  validate,
                } = object

                const required = validate && !validRow(object, index)
                return (
                  <Flex
                    p={{ base: 5, calc: 0 }}
                    mx={3}
                    mb={5}
                    position="relative"
                    direction={{ base: "column", calc: "row" }}
                    border={{ base: "1px solid", calc: "none" }}
                    borderColor="gray.300"
                  >
                    <RowHeading mt={0}>From*</RowHeading>
                    <Flex minHeight="36px">
                      <Column minWidth="250px" flexGrow={1}>
                        <AirportSelector
                          inputColors={inputColors}
                          options={airportOptions}
                          value={origin}
                          onChange={({ value }) =>
                            changeValue(index, "origin", value)
                          }
                          required={required}
                        />
                      </Column>
                      <Column width="40px" justifyContent="center">
                        <SwapButton
                          as="button"
                          height="36px"
                          onClick={e => {
                            e.preventDefault()
                            if (destination || origin) {
                              let oldValues = [...values]
                              oldValues[index] = {
                                ...values[index],
                                origin: destination,
                                destination: origin,
                              }
                              setValues(oldValues)
                              updateCalcButton()
                            }
                          }}
                        >
                          <svg
                            width="36"
                            height="22"
                            viewBox="0 0 36 22"
                            fill="none"
                          >
                            <path
                              d="M35.9582 7.03314L26.2395 13.124V0.942261L35.9582 7.03314Z"
                              fill={colors.blue["400"]}
                            />
                            <path
                              d="M0 15.0331L9.71866 8.94226V21.124L0 15.0331Z"
                              fill={colors.blue["400"]}
                            />
                            <path
                              d="M24 18H8.99997V12L24 12V18Z"
                              fill={colors.blue["400"]}
                            />
                            <path
                              d="M12 4H27V10H12V4Z"
                              fill={colors.blue["400"]}
                            />
                          </svg>
                        </SwapButton>
                      </Column>
                    </Flex>
                    <RowHeading>To*</RowHeading>
                    <Column minWidth="250px" flexGrow={1}>
                      <AirportSelector
                        inputColors={inputColors}
                        options={airportOptions}
                        value={destination}
                        onChange={({ value }) =>
                          changeValue(index, "destination", value)
                        }
                        required={required}
                      />
                    </Column>
                    <RowHeading>Round Trips*</RowHeading>
                    <Column width="220px" mx={{ base: 0, calc: 5 }}>
                      <TextField
                        inputColors={inputColors}
                        required={!trips && required}
                        value={trips}
                        placeholder="#"
                        width="70px"
                        mr={2}
                        onChange={inputValue => {
                          let value = inputValue.target.value
                          value = value.replace(/\D/g, "")
                          value = value.replace(/^[0]/, "")
                          changeValue(index, "trips", value)
                        }}
                      />
                      <Box flexGrow={1}>
                        <FrequencySelector
                          inputColors={inputColors}
                          value={frequency}
                          onChange={({ value }) =>
                            changeValue(index, "frequency", value)
                          }
                          required={required}
                        />
                      </Box>
                    </Column>
                    <RowHeading>Class of Travel</RowHeading>
                    <Column width="150px">
                      <Box flexGrow={1}>
                        <ClassSelector
                          inputColors={inputColors}
                          value={travelClass}
                          options={getTrevelClassOptions(
                            airportOptions,
                            origin,
                            destination
                          )}
                          onChange={({ value }) =>
                            changeValue(index, "travelClass", value)
                          }
                        />
                      </Box>
                    </Column>
                    <RowHeading
                      textAlign={{ base: "left", calc: "center" }}
                      color="green.500"
                    >
                      Hotel nights per Trip
                    </RowHeading>
                    <Column
                      width="120px"
                      justifyContent="center"
                      pl={{ base: 0, calc: 5 }}
                    >
                      <TextField
                        inputColors={{
                          ...inputColors,
                          borderColorIsFocused: colors && colors.green["300"],
                          borderColor: colors && colors.green["400"],
                          bg: colors && colors.green["50"],
                          placeholderColor: colors.green["400"],
                        }}
                        className="hotel-nights-per-trip"
                        value={nights}
                        placeholder="#"
                        width="100%"
                        onChange={inputValue => {
                          let value = inputValue.target.value
                          value = value.replace(/\D/g, "")
                          value = value.replace(/^[0]/, "")
                          changeValue(index, "nights", value)
                        }}
                      />
                    </Column>
                    <Flex
                      mt={{ base: 4, calc: 0 }}
                      alignItems="center"
                      width="22px"
                      position={{ base: "relative", calc: "absolute" }}
                      height="100%"
                      width={{ base: "100%", calc: "auto" }}
                      justifyContent={{ base: "center", calc: "unset" }}
                      right={{ base: "unset", calc: "-30px" }}
                    >
                      {values.length > 1 && (
                        <CloseButton
                          display={{ base: "none", calc: "block" }}
                          color="white"
                          bg="gray.400"
                          _focus={{
                            outline: 0,
                          }}
                          onClick={e => {
                            const newValues = values.filter(
                              (_, i) => i !== index
                            )
                            setValues(newValues.map(item => ({ ...item })))
                            updateCalcButton()
                          }}
                          _hover={{ bg: "gray.600" }}
                          size="sm"
                        />
                      )}
                      {values.length > 1 && (
                        <Button
                          display={{ base: "block", calc: "none" }}
                          onClick={e => {
                            const newValues = values.filter(
                              (_, i) => i !== index
                            )
                            setValues(newValues.map(item => ({ ...item })))
                            updateCalcButton()
                          }}
                          size="md"
                        >
                          Remove
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                )
              })}
            </Flex>
            <Flex stretch={1} my={4} direction="column" alignItems="center">
              <Box width="100%" height="1px" bg="gray.300" zIndex={-1} />

              <Box bg="white" height="100%" mt="-22px">
                <Button
                  height="44px"
                  width="44px"
                  onClick={addOneMoreRoute}
                  borderRadius="50%"
                  disabled={values.length >= MAX_ROWS}
                  boxShadow="1px 2px 3px 0 rgba(0,0,0,0.2), 1px 2px 5px 0 rgba(0,0,0,0.08)"
                >
                  <Text
                    fontSize="3xl"
                    mt="-3px"
                    ml="1px"
                    fontWeight="semibold"
                    lineHeight="24px"
                  >
                    +
                  </Text>
                </Button>
              </Box>
              {values.length >= MAX_ROWS && (
                <Text fontSize="sm">
                  <i>{limitReachedLabel}</i>
                </Text>
              )}
            </Flex>
          </Flex>
          <Button
            isLoading={loading}
            onClick={onSubmit}
            disabled={calcButtonType == BUTTON_TYPE_NONE}
          >
            <Text
              dangerouslySetInnerHTML={{
                __html:
                  calcButtonType === BUTTON_TYPE_RECALC ||
                  calcButtonType == BUTTON_TYPE_NONE
                    ? recalculateButtonLabel
                    : calculateButtonLabel,
              }}
            />
          </Button>
          <Box
            ref={resultsBreakLineRef}
            position={"absolute"}
            bottom="-24px"
            opacity={showValidationMessage ? 1 : 0}
            color="red.500"
            fontSize="sm"
            transition="opacity 0.5s"
          >
            <i>*Please fill in all required fields</i>
          </Box>
        </Section>
        {!calculatedResults && <Bar mt={5} />}
      </SectionWrapper>
      {calculatedResults && (
        <Box mt={10} borderTop={`2px dashed ${colors.gray[200]}`} width="100%">
          <Results
            {...calculatedResults}
            numberOfEmployees={resultEmployees}
            calculationType={resultTypeCompany ? "company" : "individual"}
            heading={
              <Heading
                as="h3"
                fontSize={{ base: "2xl", md: "3xl" }}
                fontWeight="normal"
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html: resultTypeCompany
                    ? resultsHeadingCompany.replace("[number]", resultEmployees)
                    : resultsHeading,
                }}
              ></Heading>
            }
            charts={charts}
          />
          {shareableUrl && <Share {...shareSection} link={shareableUrl} />}
        </Box>
      )}
    </>
  )
}

const Column = ({ children, ...props }) => (
  <Flex minHeight="36px" {...props}>
    {children}
  </Flex>
)

const ColumnHeading = ({ children, ...props }) => (
  <Text fontWeight="bold" fontSize="md" lineHeight="120%" {...props}>
    {children}
  </Text>
)

const RowHeading = ({ children, ...props }) => (
  <ColumnHeading
    pt={4}
    pb={1}
    display={{ base: "block", calc: "none" }}
    {...props}
  >
    {children}
  </ColumnHeading>
)

export default Calculator
