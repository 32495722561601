import React, { useState, useLayoutEffect } from "react"
import { round } from "../../utils"
import { Box, Flex, Text } from "@chakra-ui/core"

import InfographicContainer from "./infographic-container"

const Chart = ({
  tonnes,
  comparisonValue,
  comparisonValueLabel,
  calculatedValueLabel,
}) => {
  const [hydeParkSideRatio, setHydeParkSideRatio] = useState(0)
  const [acresNeededSideRatio, setAcresNeededSideRatio] = useState(0)
  const acresNeeded = tonnes / 2.5

  useLayoutEffect(() => {
    // The reason why we need to use ratio of sides rather than areas is because if we use ratio of areas
    // we will need to scale the circle and that will also scale the border that is added in css, as border
    // scales down it will be almost invisible and not per design.
    // By using the ratio between sides we can account for the border in css no matter what is the size of the circles
    let hydeParkRadius = Math.sqrt(comparisonValue / Math.PI)
    let acresNeededRadius = Math.sqrt(acresNeeded / Math.PI)

    const interval = setTimeout(() => {
      if (hydeParkRadius > acresNeededRadius) {
        setHydeParkSideRatio(100)
        setAcresNeededSideRatio(
          Math.round((100 / hydeParkRadius) * acresNeededRadius)
        )
      } else {
        setAcresNeededSideRatio(100)
        setHydeParkSideRatio(
          Math.round((100 / acresNeededRadius) * hydeParkRadius)
        )
      }
    }, 1000)

    return () => clearTimeout(interval)
  }, [tonnes, comparisonValue])

  return (
    <div
      style={{
        width: "300px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          height: `${acresNeededSideRatio}px`,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
          zIndex: acresNeededSideRatio < hydeParkSideRatio ? 1 : 0,
          position: "absolute",
          bottom: 0,
        }}
      >
        <Box
          bg="green.200"
          border="1px solid"
          borderColor="green.200"
          style={{
            boxSizing: "border-box",
            height: `${acresNeededSideRatio}px`,
            width: `${acresNeededSideRatio}px`,
            transition: "0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
            borderRadius: `50%`,
            position: "absolute",
            bottom: 0,
          }}
        />
        <div
          style={{
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000",
            left: 0,
            top: acresNeededSideRatio > 20 ? "0" : "unset",
            bottom: acresNeededSideRatio > 20 ? "unset" : "100%",
            position: "absolute",
            width: "50%",
            borderTop: acresNeededSideRatio > 20 ? "1px dashed black" : "unset",
            borderBottom:
              acresNeededSideRatio > 20 ? "unset" : "1px dashed black",
            textAlign: "left",
          }}
          dangerouslySetInnerHTML={{
            __html: calculatedValueLabel.replace(
              "[number]",
              round(acresNeeded, 1).toLocaleString("en")
            ),
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: `${hydeParkSideRatio}px`,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
          zIndex: acresNeededSideRatio < hydeParkSideRatio ? 0 : 1,
          position: "absolute",
          bottom: 0,
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            height: `${hydeParkSideRatio}px`,
            width: `${hydeParkSideRatio}px`,
            transition: "1s cubic-bezier(0.175, 0.885, 0.32, 1.275)", //ease-in-out
            borderRadius: `50%`,
            background:
              acresNeededSideRatio == hydeParkSideRatio
                ? "transparent"
                : "#fff",
            border: "1px dashed #000",
            position: "absolute",
            bottom: 0,
          }}
        ></div>
        <div
          style={{
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000",
            right: 0,
            top: hydeParkSideRatio > 20 ? "0" : "unset",
            bottom: hydeParkSideRatio > 20 ? "unset" : "100%",
            position: "absolute",
            width: "50%",
            borderTop: hydeParkSideRatio > 20 ? "1px dashed black" : "unset",
            borderBottom: hydeParkSideRatio > 20 ? "unset" : "1px dashed black",
            textAlign: "right",
          }}
          dangerouslySetInnerHTML={{
            __html: comparisonValueLabel.replace(
              "[number]",
              comparisonValue.toLocaleString("en")
            ),
          }}
        />
      </div>
    </div>
  )
}

const AnnualTreesBudget = ({
  kg,
  employees,
  title,
  summary,
  calculatedValueLabel,
  comparisonValueLabelOne,
  comparisonValueOne,
  comparisonValueLabelTwo,
  comparisonValueTwo,
  icon,
}) => (
  <InfographicContainer
    icon={icon}
    chart={
      <Chart
        tonnes={kg / 1000}
        comparisonValue={
          employees > 1 ? comparisonValueOne : comparisonValueTwo
        }
        comparisonValueLabel={
          employees > 1 ? comparisonValueLabelOne : comparisonValueLabelTwo
        }
        calculatedValueLabel={calculatedValueLabel}
      />
    }
    title={title}
    text={summary.replace("[number]", Math.ceil(kg / 22).toLocaleString("en"))}
  />
)

export default AnnualTreesBudget
