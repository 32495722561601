import React, { useState } from "react"
import { Flex, Box, Text } from "@chakra-ui/core"

const Toggle = ({ on, off, value, onChange }) => {
  return (
    <Flex
      bg="gray.700"
      border="2px solid"
      borderColor="gray.700"
      cursor="pointer"
      color="white"
      borderRadius="6px"
      alignItems="center"
      justifyContent="space-around"
      position="relative"
      width="300px"
      boxShadow="inset 0px 0px 1px #000000, 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06);"
    >
      <Flex
        alignItems="center"
        justifyContent="space-around"
        width="50%"
        fontWeight="semibold"
        fontSize="md"
        color="white"
        py={1}
        onClick={e => {
          onChange()
        }}
      >
        {on}
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-around"
        width="50%"
        fontWeight="semibold"
        fontSize="md"
        color="white"
        py={1}
        onClick={e => {
          onChange()
        }}
      >
        {off}
      </Flex>
      <Flex
        boxShadow="0px 0px 1px #000000"
        onClick={e => e.preventDefault()}
        style={{
          width: "50%",
          cursor: "default",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          background: "white",
          color: "black",
          position: "absolute",
          height: "100%",
          right: value == true ? "50%" : "0",
          transition: "0.3s ease-in-out",
        }}
      >
        <Text color="gray.800" fontWeight="semibold" fontSize="md">
          {value == true ? on : off}
        </Text>
        <Box ml={1} mr={"-14px"}>
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 7L5 11L13 1" stroke="#63CF70" stroke-width="2" />
          </svg>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Toggle
