import React, { useState, useLayoutEffect } from "react"
import { round } from "../../utils"
import { Box, Flex, Text } from "@chakra-ui/core"

import InfographicContainer from "./infographic-container"

const Chart = ({
  tonnes,
  employees,
  calculatedValueLabel,
  comparisonValueLabel,
  comparisonValue,
}) => {
  const [budgetPercent, setBudgetPercent] = useState(0)
  const [recommendedPercent, setRecommendedPercent] = useState(0)
  const [recommended, setRecommended] = useState(0)

  useLayoutEffect(() => {
    const newRecommended = employees * comparisonValue
    setRecommended(newRecommended)

    const interval = setTimeout(() => {
      if (tonnes > newRecommended) {
        setBudgetPercent(100)
        setRecommendedPercent((100 / tonnes) * newRecommended)
      } else {
        setBudgetPercent((100 / newRecommended) * tonnes)
        setRecommendedPercent(100)
      }
    }, 1000)

    return () => clearTimeout(interval)
  }, [tonnes, employees])

  return (
    <div
      style={{
        width: "300px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "30px",
          width: `100%`,
          position: "relative",
          marginBottom: "6px",
        }}
      >
        <Box
          bg="red.200"
          style={{
            height: "30px",
            width: `${budgetPercent}%`,
            transition: "0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
            borderRadius: "8px",
          }}
        />
        <Box
          color="red.800" //{budgetPercent < 100 ? "black" : "white"}
          fontSize="sm"
          fontWeight="medium"
          style={{
            position: "absolute",
            width: `100%`,
            top: 0,
            textAlign: "center",
            lineHeight: "30px",
          }}
        >
          {calculatedValueLabel.replace(
            "[number]",
            round(tonnes, 1).toLocaleString("en")
          )}
        </Box>
      </div>
      <div
        style={{
          height: "30px",
          width: `100%`,
          position: "relative",
        }}
      >
        <Box
          bg="green.200"
          style={{
            height: "30px",
            width: `${recommendedPercent}%`,
            transition: "0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
            borderRadius: "8px",
          }}
        />
        <Box
          color="green.800" //{recommendedPercent < 100 ? "black" : "white"}
          fontSize="sm"
          fontWeight="medium"
          style={{
            position: "absolute",
            width: `100%`,
            top: 0,
            textAlign: "center",
            lineHeight: "30px",
          }}
        >
          {comparisonValueLabel.replace(
            "[number]",
            recommended.toLocaleString("en")
          )}
        </Box>
      </div>
    </div>
  )
}

const AnnualCO2Budget = ({
  kg,
  employees,
  title,
  summary,
  calculatedValueLabel,
  comparisonValueLabelOne,
  comparisonValueOne,
  icon,
}) => (
  <InfographicContainer
    icon={icon}
    title={title}
    chart={
      <Chart
        tonnes={kg / 1000}
        employees={employees}
        calculatedValueLabel={calculatedValueLabel}
        comparisonValueLabel={comparisonValueLabelOne}
        comparisonValue={comparisonValueOne}
      />
    }
    text={summary}
  />
)

export default AnnualCO2Budget
