import React from "react"
import { Box, Flex, Text } from "@chakra-ui/core"
import styled from "@emotion/styled"

const StyledText = styled(Text)`
  b {
    font-weight: ${({ theme }) => theme.fontWeights["bold"]};
    color: ${({ theme }) => theme.colors.gray[700]};
  }
`

const InfographicContainer = ({ icon, title, chart, text }) => (
  <Flex direction="column" alignItems="center">
    <Flex direction="column" alignItems="center" mb={3}>
      <img src={icon.url} style={{ height: "60px", width: "60px" }} />
      <Text
        as="h4"
        color="gray.800"
        style={{ fontKerning: "none" }}
        fontWeight="normal"
        fontSize="xl"
        dangerouslySetInnerHTML={{ __html: title }}
        my={1}
        borderBottom="1px solid"
        borderColor="green.200"
      />
    </Flex>
    {chart}
    <StyledText
      mt={5}
      lineHeight="20px"
      fontSize="15px"
      color="gray.650"
      fontWeight="normal"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </Flex>
)

export default InfographicContainer
