import React from "react"
import { Button as ChakraButton, Text, useTheme } from "@chakra-ui/core"
import { css } from "@emotion/core"

const BUTTON_SIZING = {
  lg: {
    fontSize: "md",
    fontWeight: "semibold",
    py: "2",
    px: "5",
  },
  md: {
    fontSize: "sm",
    fontWeight: "semibold",
    py: "2",
    px: "3",
  },
  sm: {
    fontSize: "sm",
    fontWeight: "medium",
    py: "1",
    px: "2",
  },
}

const Button = ({ children, size, ...props }) => {
  const { colors, fontSizes, fontWeights, space } = useTheme()
  const sizeToUse = size ? size : "lg"
  return (
    <ChakraButton
      bg="gray.400"
      color="gray.800"
      boxShadow="sm"
      position="relative"
      transition="color 0.1s ease-in, background 0.1s ease-in"
      lineHeight="normal"
      top="-1px"
      _focus={{ outline: 0 }}
      _active={{
        bg: "gray.600",
        color: "gray.50",
      }}
      style={{
        wordSpacing: "2px",
        padding: `${space[BUTTON_SIZING[sizeToUse].py]} ${
          space[BUTTON_SIZING[sizeToUse].px]
        }`,
        fontSize: fontSizes[BUTTON_SIZING[sizeToUse].fontSize],
        fontWeight: fontWeights[BUTTON_SIZING[sizeToUse].fontWeight],
      }}
      css={css`
        @media screen and (min-width: 30em) {
          &:hover {
            background: ${colors.gray["800"]};
            color: ${colors.gray["200"]};
          }
        }
      `}
      height="auto"
      {...props}
    >
      {children}
    </ChakraButton>
  )
}

export default Button
