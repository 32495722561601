import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

export const parseMetadataFromPrismic = (node, lang) => {
  let title = node.display_name && node.display_name.length > 0 ? node.display_name[0].text : null
  // Prefer 'page_title' as default
  title = node.page_title ? node.page_title : title

  const metaTitle = node.meta_title && node.meta_title.length > 0 ? node.meta_title[0].text : title
  const description = node.meta_description && node.meta_description.length > 0 ? node.meta_description[0].text : null
  const canonicalPathname = node.canonical_url && node.canonical_url.length > 0 ? node.canonical_url[0].text : null

  const social = {}
  social.description = node.card_description || description // Set the main description as a default
  social.image = node.card_imageSharp ? node.card_imageSharp.childImageSharp.fixed.src : null
  social.imageSquare = node.card_image_squareSharp ? node.card_image_squareSharp.childImageSharp.fixed.src : null
  social.title = node.card_title && node.card_title.length > 0 ? node.card_title[0].text : title
  return {
    description,
    title,
    metaTitle,
    social,
    canonicalPathname,
    lang,
  }
}

const SEO = ({ title, siteTitle, host, lang, social, pathname, canonicalPathname, article }) => {
  const seo = {
    title: title || siteTitle,
    url: `${host}${pathname || ''}`,
  }

  const canonicalUrl = canonicalPathname || host

  const titleTemplate = siteTitle ? `${siteTitle} | %s` : `%s`
  return (
    <>
      <Helmet title={seo.title} defaultTitle={siteTitle} titleTemplate={titleTemplate}>
        <html lang={lang} />
        <meta charSet="utf-8" />
        <meta name="description" content={social.description} />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <meta property="og:url" content={seo.url} />
      
        <meta property="og:type" content="website" />
        <meta property="og:title" content={social.title} />
        <meta property="og:description" content={social.description} />

        <meta property="og:image" content={social.imageSquare} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@cecubed" />
        {social.title && <meta name="twitter:title" content={social.title} />}
        {social.description && <meta name="twitter:description" content={social.description} />}
        <link rel="image_src" href={social.imageSquare} />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Company",
            "url": "${seo.url}",
            "name": "${seo.title}"
          }`}
        </script>
      </Helmet>
    </>
  )
}

export default SEO
SEO.propTypes = {
  title: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  siteTitle: PropTypes.string,
  canonicalPathname: PropTypes.string,
  alternateLanguages: PropTypes.array,
  social: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    imageSquare: PropTypes.string,
  }),
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  social: {},
  image: null,
  siteTitle: null,
  canonicalPathname: null,
  alternateLanguages: null,
  pathname: null,
  article: false,
}
