import React from "react"
import { Heading, Box } from "@chakra-ui/core"

const SectionHeading = ({ children, ...props }) => (
  <Box {...props}>
    <Heading
      as="h2"
      color="gray.700"
      fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
      fontWeight="normal"
    >
      {children}
    </Heading>
    <Box
      width="80px"
      height="2px"
      bg="gray.700"
      mt={2}
      zIndex="1"
      mb={2}
      position="relative"
      _before={{
        content: "''",
        bg: "white",
        height: "100%",
        width: "5px",
        top: 0,
        position: "absolute",
        right: "10px",
      }}
      _after={{
        content: "''",
        bg: "white",
        height: "100%",
        width: "5px",
        top: 0,
        position: "absolute",
        right: "25px",
      }}
    />
  </Box>
)

export default SectionHeading
