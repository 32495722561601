import React, { useState } from "react"
import { Link } from "gatsby"
import { Box, Heading, Flex, Text } from "@chakra-ui/core"
import { SectionWrapper, Section } from "../design-system/layout"
import Map from "../design-system/map"
import Button from "../design-system/button"
import DownloadButton from "../design-system/download-button"

import { css } from "@emotion/core"

const Hero = ({
  title,
  summary,
  signupSuccess,
  signupPlaceholder,
  signupButton,
  campaign,
  cfaButton,
}) => {
  const [email, setEmail] = React.useState("")
  const [showError, toggleError] = React.useState(false)
  const [showSuccess, toggleSuccess] = React.useState(false)

  React.useEffect(() => {
    let timeout = null
    if (showError) {
      timeout = setTimeout(() => {
        toggleError(false)
      }, 10000)
    }

    return () => timeout && clearTimeout(timeout)
  }, [showError])

  React.useEffect(() => {
    let timeout = null
    if (showSuccess) {
      timeout = setTimeout(() => {
        toggleSuccess(false)
      }, 10000)
    }

    return () => timeout && clearTimeout(timeout)
  }, [showSuccess])

  return (
    <SectionWrapper pt="136px" alightItems="center" justifyContent="center">
      <SectionWrapper
        width="100%"
        position="relative"
        height={{ base: "210px", md: "270px" }}
      >
        <Box
          bg="gray.100"
          height="100%"
          width="100vw"
          position="absolute"
          left={0}
          zIndex="hide"
          right={0}
          top={0}
          css={css`
            clip-path: polygon(100% 25%, 0% 0%, 0% 100%, 100% 80%);
            -webkit-clip-path: polygon(100% 25%, 0% 0%, 0% 100%, 100% 80%);
            @media screen and (min-width: 48em) {
              clip-path: polygon(100% 30%, 0% 0%, 0% 100%, 100% 75%);
              -webkit-clip-path: polygon(100% 30%, 0% 0%, 0% 100%, 100% 75%);
            }
          `}
        />
        <Section
          direction="row"
          alignItems="center"
          position="relative"
          height="100%"
        >
          <Heading
            as="h1"
            fontSize={{ base: "4xl", sm: "5xl", md: "6xl" }}
            fontWeight="normal"
            color="gray.800"
            my={5}
            mx={3}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Flex
            position="absolute"
            left={{ base: "0", md: "unset" }}
            right={{ base: "unset", md: "0" }}
            justifyContent="flex-end"
            zIndex="hide"
            width="100%"
            pt={{ base: 32, md: 24 }}
            pb={12}
            pl={5}
          >
            <Box maxWidth="100vw" overflow="hidden">
              <Map />
            </Box>
          </Flex>
        </Section>
      </SectionWrapper>
      <Section alignItems="center">
        <Box mx={3}>
          <Heading
            as="h2"
            fontSize="xl"
            fontWeight={{ base: "light", md: "normal" }}
            fontFamily="body"
            color="gray.700"
            width={{ base: "100%", md: "30rem" }}
            mb={5}
            mt={{ base: 10, md: 12 }}
            dangerouslySetInnerHTML={{ __html: summary }}
          />
          {cfaButton && (
            <DownloadButton id="cfa-hero-download-report" mt={{ base: 2, md: 0 }} href={cfaButton.url}>
              {cfaButton.label}
            </DownloadButton>
          )}
        </Box>
      </Section>
    </SectionWrapper>
  )
}

export default Hero
