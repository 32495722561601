import React from "react"
import { Heading, Box } from "@chakra-ui/core"
import { keyframes } from "@emotion/core"

const move_bg = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(72px);
  }
`
const barStyles = {
  bg: "white",
  height: "1px",
  width: "100%",
  position: "absolute",
  animation: `${move_bg} 3s linear infinite`,
}

const Bar = ({ children, ...props }) => (
  <Box {...props} m={2}>
    <Box
      width="3px"
      height="72px"
      bg="green.300"
      zIndex="1"
      position="relative"
    >
      <Box {...barStyles} top="0" style={{ animationDelay: "0" }} />
      <Box {...barStyles} top="0" style={{ animationDelay: "0.05s" }} />
      <Box {...barStyles} top="0" style={{ animationDelay: "0.1s" }} />
      <Box {...barStyles} top="0" style={{ animationDelay: "0.15s" }} />
      <Box {...barStyles} top="0" style={{ animationDelay: "0.2s" }} />

      <Box {...barStyles} top="0" style={{ animationDelay: "0.5s" }} />
      <Box {...barStyles} top="0" style={{ animationDelay: "0.55s" }} />
      <Box {...barStyles} top="0" style={{ animationDelay: "0.6s" }} />
      <Box {...barStyles} top="0" style={{ animationDelay: "0.65s" }} />
      <Box {...barStyles} top="0" style={{ animationDelay: "0.7s" }} />
    </Box>
  </Box>
)

export default Bar
