import React from "react"
import { Heading as ChakraHeading, Box } from "@chakra-ui/core"
import styled from "@emotion/styled"

const StyledHeading = styled(ChakraHeading)`
  b {
    font-weight: ${({ theme }) => theme.fontWeights["semibold"]};
    border-bottom: 2px solid ${({ theme }) => theme.colors.green["300"]};
    padding-bottom: ${({ theme }) => theme.space[1]};
  }
`

const Heading = ({ children, ...props }) => (
  <StyledHeading
    as="h2"
    color="gray.700"
    fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
    fontWeight="normal"
    {...props}
  >
    {children}
  </StyledHeading>
)

export default Heading
