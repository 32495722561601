import React, { useEffect, useState } from "react"
import Select from "react-select"

const selectStyles = ({ inputColors }) => {
  return {
    container: (base, state) => ({
      ...base,
      opacity: state.isDisabled ? ".5" : "1",
      backgroundColor: "transparent",
    }),
    control: (base, state) => ({
      ...base,
      // none of react-select's styles are passed to <Control />
      background: inputColors.bg,
      "&:hover": {
        borderColor: inputColors.borderColorIsFocused,
      },
      border: !state.isFocused
        ? `1px solid ${inputColors.borderColor}`
        : `1px solid ${inputColors.borderColorIsFocused}`,
      boxSizing: "border-box",
      borderRadius: "4px",
      lineHeight: "30px",
      boxShadow: "none",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white !important" : "#000",
      cursor: "pointer",
    }),
  }
}

const ClassSelector = ({ options, onChange, value, inputColors }) => {
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => setSelectedValue(value), [value])

  return (
    <Select
      className="class-selector"
      styles={selectStyles({ inputColors })}
      classNamePrefix="class-selector"
      isSearchable={false}
      name="color"
      value={
        selectedValue ? options.find(obj => obj.value === selectedValue) : null
      } // set selected value
      onChange={onChange}
      options={options}
    />
  )
}

export default ClassSelector
