import React from "react"
import { Link } from "gatsby"
import {
  Box,
  Heading,
  Flex,
  PseudoBox,
  Button,
  Text,
  Grid,
  useTheme,
} from "@chakra-ui/core"
import { SectionWrapper, Section } from "../design-system/layout"
import { css } from "@emotion/core"

const BOX_COLOURS = ["blue", "green", "red"]

const WhyUs = ({ boxes, id }) => {
  const { colors, shadows } = useTheme()
  return (
    <SectionWrapper
      alightItems="center"
      justifyContent="center"
      bg={"gray.100"}
      my={{ base: 6, lg: 0 }}
      css={css`
        clip-path: polygon(100% 0%, 0% 3%, 0% 100%, 100% 97%);
        -webkit-clip-path: polygon(100% 0%, 0% 3%, 0% 100%, 100% 97%);
        @media screen and (min-width: 62em) {
          clip-path: polygon(100% 0%, 0% 15%, 0% 100%, 100% 85%);
          -webkit-clip-path: polygon(100% 0%, 0% 15%, 0% 100%, 100% 85%);
        }
      `}
      id={id}
    >
      <Section my={{ base: 12, lg: 32 }} justifyContent="center">
        <Box mx={3}>
          <Grid
            templateColumns={{ base: "unset", lg: "repeat(3, 296px)" }}
            templateRows={{ base: "repeat(3, auto)", lg: "unset" }}
            gap={{ base: 6, lg: 8, lg2: 16 }}
            width="100%"
            justifyContent="center"
          >
            {boxes.map(({ title, body }, index) => (
              <Box
                bg="white"
                px={3}
                py={5}
                //cursor="pointer"
                border="1px solid"
                borderTop="3px solid"
                borderRadius="2px"
                transition="all 0.3s"
                position="relative"
                top={0}
                boxShadow="md"
                css={css`
                  border-color: ${colors[BOX_COLOURS[index]]["200"]};
                  border-top-color: ${colors[BOX_COLOURS[index]]["200"]};

                  @media screen and (min-width: 62em) {
                    border-color: ${colors.gray["200"]};
                    border-top-color: ${colors[BOX_COLOURS[index]]["200"]};

                    &:hover {
                      border-color: ${colors[BOX_COLOURS[index]]["300"]};
                      box-shadow: ${shadows.lg};
                      position: relative;
                      top: -8px;
                    }
                  }
                `}
              >
                <Heading
                  fontSize="2xl"
                  as="h3"
                  fontWeight="medium"
                  p={3}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <Box
                  width="60px"
                  height="3px"
                  bg={`${BOX_COLOURS[index]}.300`}
                  mx={3}
                  mt={1}
                  zIndex="1"
                  mb={2}
                  position="relative"
                  _before={{
                    content: "''",
                    bg: "white",
                    height: "100%",
                    width: "3px",
                    top: 0,
                    position: "absolute",
                    right: "10px",
                  }}
                  _after={{
                    content: "''",
                    bg: "white",
                    height: "100%",
                    width: "3px",
                    top: 0,
                    position: "absolute",
                    right: "20px",
                  }}
                />
                <Text p={3} dangerouslySetInnerHTML={{ __html: body }} />
              </Box>
            ))}
          </Grid>
        </Box>
      </Section>
    </SectionWrapper>
  )
}

export default WhyUs
