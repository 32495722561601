import React from "react"
import { Box } from "@chakra-ui/core"
import styled from "@emotion/styled"

const StyledBox = styled(Box)`
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray["500"]};
  }
`

const TextInput = ({ ...props }) => (
  <StyledBox
    as="input"
    type="text"
    border="1px solid"
    borderRadius="4px"
    borderColor="gray.400"
    color="gray.700"
    width={{ base: "unset", md: "272px" }}
    height="36px"
    style={{ "-webkit-appearance": "none" }}
    px={3}
    _focus={{
      outline: "none",
      boxShadow: "none",
      borderColor: "gray.500",
    }}
    {...props}
  />
)

export default TextInput
