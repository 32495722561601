import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import { Box, useTheme } from "@chakra-ui/core"
import styled from "@emotion/styled"

const StyledBox = styled(Box)`
  &::placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`

const TextField = ({ onChange, value, inputColors, required, ...props }) => {
  //const [currentValue, setCurrentValue] = useState(value)
  const { colors } = useTheme()
  const inputRef = useRef(null)
  const cursorStart = useRef(null)
  const cursorEnd = useRef(null)

  useLayoutEffect(() => {
    if (cursorStart.current && cursorEnd.current) {
      inputRef.current.setSelectionRange(cursorStart.current, cursorEnd.current)
    }
  }, [value])

  const inputColorScheme = {
    borderColorIsFocused: colors.blue["500"],
    borderColorRequired: colors.red["300"],
    borderColor: colors.gray["400"],
    placeholderColor: colors.gray["500"],
    bg: null,
    ...inputColors,
  }

  return (
    <StyledBox
      as="input"
      placeholderColor={inputColorScheme.placeholderColor}
      type="text"
      border="1px solid"
      borderRadius="4px"
      height="36px"
      borderColor={
        required
          ? inputColorScheme.borderColorRequired
          : inputColorScheme.borderColor
      }
      bg={inputColorScheme.bg}
      color="gray.700"
      width="80px"
      fontWeight="bold"
      lineHeight="30px"
      textAlign="center"
      px={3}
      _focus={{
        outline: "none",
        boxShadow: "none",
        borderColor: inputColorScheme.borderColorIsFocused,
      }}
      _hover={{
        borderColor: inputColorScheme.borderColorIsFocused,
      }}
      style={{ "-webkit-appearance": "none" }}
      value={value || ""}
      onChange={onChange}
      ref={inputRef}
      {...props}
    />
  )
}

export default TextField
