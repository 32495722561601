/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const calculate = /* GraphQL */ `
  query Calculate($object: AWSJSON) {
    calculate(object: $object) {
      totalDistanceKM
      totalEmissionsKG
    }
  }
`

export const calculateV2 = /* GraphQL */ `
  query CalculateV2($object: AWSJSON) {
    calculateV2(object: $object)
  }
`

export const calculateEmissions = /* GraphQL */ `
  query CalculateEmissions(
    $calculationType: String
    $numberOfEmployees: Int
    $object: AWSJSON
  ) {
    calculateEmissions(
      calculationType: $calculationType
      numberOfEmployees: $numberOfEmployees
      object: $object
    )
  }
`
export const getResults = /* GraphQL */ `
  query GetResults($id: String) {
    getResults(id: $id)
  }
`
